.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
header{
  background-color: #222222;
}

.logo-subrayado{
  width: 210px;
  padding-top: 10px;
}

.title-tuvoz{
  color: white;
  font-size: 4rem;
  font-family: 'Montserrat';
  font-weight: 100;
}
.title-tuvoz strong{
  font-weight: 900;
}
.description{
  font-size: 1.4em;
}

.form-principal{
  background-color: #ffcd00;
  padding-top: 60px;
}

.App-link {
  color: #61dafb;
}

.btn-office{
  border-radius: 0 !important;
  color: #fff !important;
  background-color: black !important;
  border-color: black !important;
  max-width: 350px !important;
  font-weight: 700 !important;
}

.content-answers{
  display: block;
}

.content-rango{
  display: block;
  text-align: center;
}

.rango{
  display: inline-block !important;
}

.description-rango{
  font-weight: 500;
  font-size: .9em;
  padding-top: 10px;
}

.footer{
  background-color: #222222;
  color: white;
}

.footer a{
  color: white;
  font-weight: 700;
  text-decoration: none;
}
.footer a:hover{
  color: #ffcd00;
}
DatePicker{
  background-color: red;
  width: 100%;
}
.react-date-picker{
  width: 100%;

}
.react-date-picker__wrapper{
  border-color: #f58f21 !important;
  border-radius: 0.25rem;
  padding: 4px;
}

.ml-10{
  margin-left: 15px;
}

.progress-top{
  position: fixed;
  top: 0;
  /* background-color: rgb(224, 224, 224); */
  background-color: rgb(255, 205, 0);
  padding-bottom: 13px;
}
.progress-bar{
  background-color: black !important;
}
.quest-content{
  padding-top: 70px;
}
.ticket-example{
  height: 500px;
}
.icon-error{
  font-size: 5em;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

