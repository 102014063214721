@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,700;0,900;1,300&display=swap);
/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;1,700&display=swap'); */
body {
  margin: 0;
  font-family: "Montserrat",sans-serif !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* #root{
  background-color: #ffcd00;
} */
.max{
  font-weight: 900;
}
.titlesform{
  font-weight: 900 !important;
  font-size: 1.4em;
}
.label-max{
  font-size: 14px;
}
.react-date-picker__wrapper{
  background-color: white;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1{
  font-size: 4rem;
}

h2{
  font-weight: 100 !important;
}


.pl-5{
  padding-left: 15px;
}

.error-quest{
  color: #f58f21 !important;
  font-weight: 700;
}

.brand-office{
  color: #f58f21 !important;
  font-weight: 700;
}

.h-finally{
  font-size: 3.5rem;
  font-weight: 700;
}

.h-finally-vale{
  font-size: 3.5rem;
  font-weight: 700;
}

.btn-vale {
  padding: 0.375 rem 2.75 rem;
}

.social-media a{
  font-weight: 400;
  text-decoration: none;
}
.social-media a:hover{
  color: #ffcd00;
}
.social-media svg{
  font-size: 1.2em;
}


.input-quest{
  border: 1px solid #ffcd00 !important;
  border-radius: 0px !important;
}

#go{
  padding: 20px 50px;
  font-size: 1.5rem;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
header{
  background-color: #222222;
}

.logo-subrayado{
  width: 210px;
  padding-top: 10px;
}

.title-tuvoz{
  color: white;
  font-size: 4rem;
  font-family: 'Montserrat';
  font-weight: 100;
}
.title-tuvoz strong{
  font-weight: 900;
}
.description{
  font-size: 1.4em;
}

.form-principal{
  background-color: #ffcd00;
  padding-top: 60px;
}

.App-link {
  color: #61dafb;
}

.btn-office{
  border-radius: 0 !important;
  color: #fff !important;
  background-color: black !important;
  border-color: black !important;
  max-width: 350px !important;
  font-weight: 700 !important;
}

.content-answers{
  display: block;
}

.content-rango{
  display: block;
  text-align: center;
}

.rango{
  display: inline-block !important;
}

.description-rango{
  font-weight: 500;
  font-size: .9em;
  padding-top: 10px;
}

.footer{
  background-color: #222222;
  color: white;
}

.footer a{
  color: white;
  font-weight: 700;
  text-decoration: none;
}
.footer a:hover{
  color: #ffcd00;
}
DatePicker{
  background-color: red;
  width: 100%;
}
.react-date-picker{
  width: 100%;

}
.react-date-picker__wrapper{
  border-color: #f58f21 !important;
  border-radius: 0.25rem;
  padding: 4px;
}

.ml-10{
  margin-left: 15px;
}

.progress-top{
  position: fixed;
  top: 0;
  /* background-color: rgb(224, 224, 224); */
  background-color: rgb(255, 205, 0);
  padding-bottom: 13px;
}
.progress-bar{
  background-color: black !important;
}
.quest-content{
  padding-top: 70px;
}
.ticket-example{
  height: 500px;
}
.icon-error{
  font-size: 5em;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


