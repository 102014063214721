/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;1,700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,700;0,900;1,300&display=swap');
body {
  margin: 0;
  font-family: "Montserrat",sans-serif !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* #root{
  background-color: #ffcd00;
} */
.max{
  font-weight: 900;
}
.titlesform{
  font-weight: 900 !important;
  font-size: 1.4em;
}
.label-max{
  font-size: 14px;
}
.react-date-picker__wrapper{
  background-color: white;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1{
  font-size: 4rem;
}

h2{
  font-weight: 100 !important;
}


.pl-5{
  padding-left: 15px;
}

.error-quest{
  color: #f58f21 !important;
  font-weight: 700;
}

.brand-office{
  color: #f58f21 !important;
  font-weight: 700;
}

.h-finally{
  font-size: 3.5rem;
  font-weight: 700;
}

.h-finally-vale{
  font-size: 3.5rem;
  font-weight: 700;
}

.btn-vale {
  padding: 0.375 rem 2.75 rem;
}

.social-media a{
  font-weight: 400;
  text-decoration: none;
}
.social-media a:hover{
  color: #ffcd00;
}
.social-media svg{
  font-size: 1.2em;
}


.input-quest{
  border: 1px solid #ffcd00 !important;
  border-radius: 0px !important;
}

#go{
  padding: 20px 50px;
  font-size: 1.5rem;
}